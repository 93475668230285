// useTranslation é um hook 
// que devolve uma função de tradução (t) e a instância do i18n

// Importa as bandeiras (imagens e componente)
import { Flag_of_Argentina, Flag_of_Brasil, Flag_of_Chile, Flag_of_Colombia, Flag_of_Mexico, Flag_of_Peru, Flag_of_USA } from '../../assets';

import { useDispatch, useSelector } from 'react-redux';

import { changeLanguage } from '../../services/lang';





const FlagChanger = () => {
  const dispatch = useDispatch()

  const state = useSelector((state: any) => state.langs)


  function handleChangeLanguage(language: any) {

    dispatch(changeLanguage(""))
  }

  function flagSelector() {
    if (state === 'pt-BR') {
      return Flag_of_Brasil;
    }
    else if (state === 'en-US') {
      return Flag_of_USA;
    }
    else if (state === 'es-AR') {
      return Flag_of_Argentina;
    }
    else if (state === 'es-CL') {
      return Flag_of_Chile;
    }
    else if (state === 'es-CO') {
      return Flag_of_Colombia;
    }
    else if (state === 'es-MX') {
      return Flag_of_Mexico;
    }
    else if (state === 'es-PE') {
      return Flag_of_Peru;
    }
  }


  return (
    <>
      <button onClick={() => handleChangeLanguage('placeholder')}>
        <img alt="flag" width={50} src={flagSelector()} />
      </button>

    </>
  )
}

export default FlagChanger