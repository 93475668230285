import {
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    useDisclosure
} from '@chakra-ui/react';

import { useSelector } from 'react-redux';
import i18n_selector from '../../I18N';

export default function CTAButton({ variant = "solid" }: { variant?: string }) {
    const { isOpen, onOpen, onClose } = useDisclosure()


    var color: string = '#FFF9F8'
    var bg: string = "in3.300"

    if (variant === "outline") {
        bg = '#FFF9F8';
        color = "in3.300";
    }


    const pageText:any = i18n_selector(useSelector((state: any) => state.langs))['CTAButton']


    return (
        <>
            < Button color={color} fontSize="18px" bg={bg} h='50px' variant={variant} borderRadius="34px" onClick={onOpen}>
                {pageText['buttonText']}
            </Button >

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{pageText['ModalHeader']}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text>
                        {pageText['modalbody']} <br /><br />
                        </Text>
                        <Text> <b>{pageText['username']}</b>globalhealth</Text>
                        <Text> <b>{pageText['password']}</b>GlobalHealth23@</Text>
                    </ModalBody>

                    <ModalFooter>
                        < a target="_blank" href="https://in3.app/powerBIReports/10239/ReportSection" >
                            <Button bg='in3.300' color='#FFF9F8'>{pageText['cta']}</Button>
                        </a>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}






