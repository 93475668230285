export const content_en = () => ({
    "ConceptCardList": [
        {
            "title": "GO/NO GO",
            "subtitle": "Data-driven decision-making",
            "content": "Evaluate scenarios and market opportunities in Latin American countries, conduct comparative analyses, and make investment decisions based on strategic data about market potential and competitor actions.",
            "icon": "GO/NO GO",
            "color": "purple"
        },
        {
            "title": "GO-TO-MARKET",
            "subtitle": "Informed and targeted planning for innovation",
            "content": "Analyze trends, projections, and market movements to plan product launches and the introduction of commercial strategies in Latin American countries, according to their specific characteristics.",
            "icon": "GOTOMARKET",
            "color": "yellow"
        },
        {
            "title": "MARKET EXPANSION",
            "subtitle": "Strategic analysis for market growth and consolidation",
            "content": "Explore growth opportunities in the territories where your company operates, identifying demands, new niches, and unexplored competitive gaps.",
            "icon": "MARKET EXPANSION",
            "color": "red"
        }
    ],
    "HomepageTextblock": {
        "header": "Welcome to the first Integrated Intelligence Project for the Latin American IVD Market",
        "body": ["The aim of this project is to generate accurate and in-depth data and information to support the strategic commercial and marketing planning of companies operating in the Latin American IVD market, thereby increasing the overall competitiveness of this market.", "To achieve this, methodologies and tools of Big Data, Data Science, and Artificial Intelligence will be employed to empower market professionals with strategic insights, aiming for planning and decision-making accuracy."]
    },
    "QuestionCardList": [
        {
            "title": "Trends",
            "subtitle": "General Market",
            "question": "What is the potential and market trends of the IVD market in Latin America?",
            "frequency": "Annual Report",
            "icon": "Trends"
        },
        {
            "title": "Share",
            "subtitle": "Products",
            "question": "What are the market share levels of IVD players?",
            "frequency": "Monthly Report",
            "icon": "Share"
        },
        {
            "title": "Size",
            "subtitle": "General Market",
            "question": "What is the Market Size of IVD in Latin American countries?",
            "frequency": "Monthly Report",
            "icon": "Size"
        },
        {
            "title": "Procedures",
            "subtitle": "Procedures",
            "question": "What is the proportion of procedures conducted in Latin America?",
            "frequency": "Monthly Report",
            "icon": "Procedures"
        },
        {
            "title": "Targets",
            "subtitle": "Establishments",
            "question": "What is the profile of IVD healthcare establishments in Latin America?",
            "frequency": "Monthly Report",
            "icon": "Targets"
        },
        {
            "title": "Segments",
            "subtitle": "Channels",
            "question": "How is the sale and distribution of IVD products segmented in Latin America?",
            "frequency": "Monthly Report",
            "icon": "Segments"
        }
    ],
    "RoadmapCardList": [
        {
            "cycleName": "First Cycle", "RoadMapDetails": [
                { "title": "Insights", "content": ["Size and evolution of general markets"] },
                { "title": "Data Source", "content": ["Imports by country", "Shared data"] },
                { "title": "Membership Fee", "content": ["Membership fee: $500.00 per month per country."] },
                { "title": "Benefits", "content": ["Realistic and reliable IVD market data"] },
            ]
        },
        {
            "cycleName": "Second Cycle", "RoadMapDetails": [
                { "title": "Insights", "content": ["Size and evolution of specific markets"] },
                { "title": "Data Source", "content": ["Imports by country", "Shared data", "Procedures by institution"] },
                { "title": "Membership Fee", "content": ["To be defined based on the number of participants"] },
                { "title": "Benefits", "content": ["Specific analyses of product lines and sublines"] },
            ]
        },
        {
            "cycleName": "Third Cycle", "RoadMapDetails": [
                { "title": "Insights", "content": ["Profile of the target audience"] },
                { "title": "Data Source", "content": ["Imports by country", "Shared data", "Procedures by institution", "Establishments by city"] },
                { "title": "Membership Fee", "content": ["To be defined based on the number of participants"] },
                { "title": "Benefits", "content": ["Analyses for Business Intelligence"] },
            ]
        },
        {
            "cycleName": "Fourth Cycle", "RoadMapDetails": [
                { "title": "Insights", "content": ["Purchase decision criteria and installed base evaluation"] },
                { "title": "Data Source", "content": ["Imports by country", "Shared data", "Procedures by institution", "Establishments by city", "Primary data (market research)"] },
                { "title": "Membership Fee", "content": ["To be defined based on the number of participants"] },
                { "title": "Benefits", "content": ["Detailed analysis of the target audience and consumption trends"] },
            ]
        },
    ],
    "RoadmapStep": [
        // { description: "Kick-off", title: "September/23" },
        { "description": "Project Start", "title": "October / 2023" },
        { "description": "", "title": "January / 2024" },
        { "description": "", "title": "April / 2024" },
        { "description": "", "title": "July / 2024" },
    ],
    "CTAButton": {
        "buttonText": "Access the demo",
        "ModalHeader": "Access Information",
        "modalbody": "To access the LATAM Intelligence dashboard demo, use the following information:",
        "username": "Username:",
        "password": "Password:",
        "cta": "Access",
    },
    "GlobeHero": {
        "gdp": "GDP:",
        "pop": "Population:"
    },
    "SunburstHero": {
        "absoluteText": "Total analyzed Market Size",
        "percentageText": "Market Share of the selected segment",
        "Argentina": "Argentina",
        "Brasil": "Brazil",
        "Chile": "Chile",
        "Colômbia": "Colombia",
        "México": "Mexico",
        "Peru": "Peru",
        "Empresa_01": "Company 01",
        "Empresa_02": "Company 02",
        "Empresa_05": "Company 05",
        "Empresa_06": "Company 06",
        "Empresa_07": "Company 07",
        "Empresa_08": "Company 08",
        "Empresa_09": "Company 09",
        "Empresa_10": "Company 10",
        "Empresa_14": "Company 14",
        "Empresa_23": "Company 23",
        "Empresa_26": "Company 26",
        "Empresa_27": "Company 27",
        "Empresa_28": "Company 28",
        "Empresa_29": "Company 29",
        "Empresa_30": "Company 30",
        "Empresa_33": "Company 33",
        "Empresa_34": "Company 34",
        "Empresa_47": "Company 47",
        "Empresa_51": "Company 51",
        "Empresa_53": "Company 53",
        "Empresa_54": "Company 54",
        "Empresa_56": "Company 56",
        "Empresa_57": "Company 57",
        "Amostras": "Samples",
        "Equipamentos": "Equipment",
        "Reagentes": "Reagents",
        "Serviços": "Services"
    },
    "UseCases":
    {
        "Text_1": "Platform Use Cases",
        "Text_2": "Make strategic decisions based on data",
    },
    "RoadMap":
    {
        "Text_1": "The Roadmap",
        "Text_2": "How we will build the project",
        "Text_3": "The project will be developed modularly. With each cycle, new databases will be incorporated to expand the scope of analyses and continuously bring more and new insights. In this first cycle, we will start with the following countries: Argentina, Brazil, Chile, Colombia, Mexico, and Peru, progressively expanding as the project progresses.",
    },
    "MarketSize":
    {
        "Text_1": "Time Series",
        "Text_2": "Evolution of the In Vitro Diagnostic Market-Size in Latin America",
        "Text_3": "Understand the market context and trends",
        "Text_4": "marketSize_en.png"
    },
    "MarketShare":
    {
        "Text_1": "In Practice",
        "Text_2": "How the analyses work",
        "Text_3": "Some demonstrations of how insights are presented",
        "Text_4": "Dimensional Analysis",
        "Text_5": "Market-Share: In Vitro Diagnostic in Latin America",
        "Text_6": "Analyze the IVD market in Latin America as a whole",
    },
    "KeyQuestions":
    {
        "Text_1": "Learn More",
        "Text_2": "Questions to be answered",
        "Text_3": "See some insights that the analyses can provide for your business",
    },
    "Companies":
    {
        "Text_1": "INTELLIGENT COMPANIES"
    }
}
)