import { Box } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import GlobeHero from "./GlobeHero";


export default function GlobeContainer() {


    const world = useRef<HTMLDivElement | null>(null);
    var worldDict: any = {};



    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });



    function handleDimensions(){
        if (world.current) {
            setDimensions({
                width: world.current.offsetWidth,
                height: world.current.offsetHeight
            });
        }
    }

    useEffect(() => {

        handleDimensions()

        window.addEventListener("resize",handleDimensions, false);

    }, [world]);



    return (

        <Box  ref={world} >
            <GlobeHero w={dimensions.width} h={dimensions.height}/>
        </Box>

    );
};








