import {
    Center,
    Grid,
    GridItem
} from "@chakra-ui/react";
import { useSelector } from 'react-redux';
import i18n_selector from "../../I18N";
import RoadmapCard from "./RoadmapCard";

export default function RoadmapCardList() {

    const cardList:any = i18n_selector(useSelector((state: any) => state.langs))['RoadmapCardList']   

    return (

        <Grid templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(4, 1fr)"}} gap={"20px"} >
            {cardList.map((cardInfo:any, index:any) => (
                <Center key={index}>
                    <GridItem w="100%"  h='100%' colSpan={1}>
                        <RoadmapCard {...cardInfo} />
                    </GridItem>
                </Center>
            ))}

        </Grid>

    )
}






