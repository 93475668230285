import {
    Box,
    Container
} from "@chakra-ui/react";
import { useSelector } from 'react-redux';
import CompaniesCardList from "../../components/CompaniesCardList";
import SectionHeader from "../../components/SectionHeader";

import i18n_selector from "../../I18N";


export const Companies = () => {
    const cardList:any = i18n_selector(useSelector((state: any) => state.langs))['Companies']   

    return(
    <Box>
        <Container maxW='100%' mt="32px" py="32px" textAlign="center">
            <SectionHeader mainHeader={cardList['Text_1']}/>
        </Container>

        <Box w='90%' mx='auto' pt='30px'>
            <CompaniesCardList />
        </Box>
    </Box >
)}