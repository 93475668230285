export default function Markets() {
 return (
[
['Chile-Reagentes-Empresa_02', 302141344],
['Chile-Reagentes-Empresa_07', 223925830],
['Argentina-Reagentes-Empresa_02', 164256684],
['México-Reagentes-Empresa_02', 163858764],
['Brasil-Reagentes-Empresa_54', 163224634],
['Colômbia-Reagentes-Empresa_02', 151317420],
['Brasil-Reagentes-Empresa_02', 139915495],
['Peru-Reagentes-Empresa_02', 139657391],
['Argentina-Reagentes-Empresa_07', 121735456],
['México-Reagentes-Empresa_07', 121440546],
['Colômbia-Reagentes-Empresa_07', 112145787],
['Brasil-Reagentes-Empresa_07', 103695486],
['Peru-Reagentes-Empresa_07', 103504197],
['Chile-Reagentes-Empresa_05', 68352289],
['Chile-Equipamentos-Empresa_02', 62318347],
['Chile-Reagentes-Empresa_14', 62090285],
['México-Reagentes-Empresa_26', 49993896],
['Chile-Reagentes-Empresa_08', 45201437],
['Brasil-Reagentes-Empresa_26', 42688719],
['México-Reagentes-Empresa_28', 37474791],
['Argentina-Reagentes-Empresa_05', 37159166],
['México-Reagentes-Empresa_05', 37069146],
['Argentina-Reagentes-Empresa_09', 34811791],
['México-Reagentes-Empresa_09', 34727457],
['Colômbia-Reagentes-Empresa_05', 34231965],
['Argentina-Equipamentos-Empresa_02', 33878862],
['México-Equipamentos-Empresa_02', 33796789],
['Argentina-Reagentes-Empresa_14', 33754878],
['México-Reagentes-Empresa_14', 33673105],
['Brasil-Reagentes-Empresa_28', 31998923],
['Brasil-Reagentes-Empresa_05', 31652551],
['Peru-Reagentes-Empresa_05', 31594161],
['Colômbia-Equipamentos-Empresa_02', 31210066],
['Colômbia-Reagentes-Empresa_14', 31095849],
['Chile-Equipamentos-Empresa_07', 29801114],
['Brasil-Reagentes-Empresa_09', 29653033],
['Brasil-Equipamentos-Empresa_02', 28858355],
['Peru-Equipamentos-Empresa_02', 28805120],
['Brasil-Reagentes-Empresa_14', 28752745],
['Peru-Reagentes-Empresa_14', 28699704],
['Argentina-Reagentes-Empresa_30', 27940803],
['México-Reagentes-Empresa_30', 27873115],
['México-Reagentes-Empresa_10', 24630768],
['Argentina-Reagentes-Empresa_08', 24573393],
['México-Reagentes-Empresa_08', 24513863],
['Brasil-Reagentes-Empresa_30', 23800257],
['Colômbia-Reagentes-Empresa_08', 22637632],
['México-Reagentes-Empresa_01', 21255633],
['Brasil-Reagentes-Empresa_10', 21031686],
['Brasil-Reagentes-Empresa_08', 20931863],
['Peru-Reagentes-Empresa_08', 20893250],
['México-Reagentes-Empresa_33', 20148259],
['México-Reagentes-Empresa_34', 19405494],
['México-Reagentes-Empresa_06', 18213586],
['Brasil-Reagentes-Empresa_01', 18149730],
['Argentina-Reagentes-Empresa_29', 17917889],
['México-Reagentes-Empresa_29', 17874482],
['Brasil-Reagentes-Empresa_33', 17204168],
['Brasil-Reagentes-Empresa_34', 16569937],
['Chile-Serviços-Empresa_07', 16352410],
['Argentina-Equipamentos-Empresa_07', 16201133],
['México-Equipamentos-Empresa_07', 16161885],
['Brasil-Reagentes-Empresa_06', 15552192],
['México-Reagentes-Empresa_56', 15429605],
['Brasil-Reagentes-Empresa_29', 15262638],
['Peru-Reagentes-Empresa_29', 15234482],
['Colômbia-Equipamentos-Empresa_07', 14924894],
['Brasil-Equipamentos-Empresa_07', 13800288],
['Peru-Equipamentos-Empresa_07', 13774830],
['Chile-Equipamentos-Empresa_08', 13471580],
['Brasil-Reagentes-Empresa_56', 13175010],
['Chile-Serviços-Empresa_02', 11611048],
['Argentina-Reagentes-Empresa_57', 11108128],
['México-Reagentes-Empresa_57', 11081218],
['Brasil-Reagentes-Empresa_57', 9462015],
['Peru-Reagentes-Empresa_57', 9444561],
['México-Reagentes-Empresa_23', 9435058],
['Argentina-Serviços-Empresa_07', 8889855],
['México-Serviços-Empresa_07', 8868318],
['Argentina-Reagentes-Empresa_53', 8732434],
['México-Reagentes-Empresa_53', 8711279],
['Brasil-Reagentes-Empresa_47', 8661962],
['Colômbia-Serviços-Empresa_07', 8189559],
['Brasil-Reagentes-Empresa_23', 8056394],
['México-Equipamentos-Empresa_10', 7943257],
['Brasil-Serviços-Empresa_07', 7572467],
['Peru-Serviços-Empresa_07', 7558498],
['Brasil-Reagentes-Empresa_53', 7438375],
['Argentina-Equipamentos-Empresa_08', 7323715],
['México-Equipamentos-Empresa_08', 7305972],
['Brasil-Equipamentos-Empresa_10', 6782578],
['Colômbia-Equipamentos-Empresa_08', 6746791],
['Argentina-Serviços-Empresa_02', 6312252],
['México-Serviços-Empresa_02', 6296960],
['Brasil-Equipamentos-Empresa_08', 6238414],
['Peru-Equipamentos-Empresa_08', 6226905],
['Chile-Equipamentos-Empresa_05', 6037487],
['Colômbia-Serviços-Empresa_02', 5815006],
['Brasil-Serviços-Empresa_02', 5376839],
['Peru-Serviços-Empresa_02', 5366921],
['México-Equipamentos-Empresa_26', 5353677],
['Argentina-Reagentes-Empresa_27', 4636817],
['México-Reagentes-Empresa_27', 4625584],
['Brasil-Equipamentos-Empresa_26', 4571390],
['Colômbia-Reagentes-Empresa_27', 4271553],
['Brasil-Reagentes-Empresa_27', 3949687],
['Peru-Reagentes-Empresa_27', 3942401],
['Argentina-Equipamentos-Empresa_09', 3682357],
['México-Equipamentos-Empresa_09', 3673437],
['Chile-Serviços-Empresa_08', 3305292],
['Argentina-Equipamentos-Empresa_05', 3282231],
['Argentina-Reagentes-Empresa_51', 3279698],
['México-Equipamentos-Empresa_05', 3274279],
['México-Reagentes-Empresa_51', 3271753],
['Brasil-Equipamentos-Empresa_09', 3136669],
['México-Equipamentos-Empresa_06', 3038340],
['Colômbia-Equipamentos-Empresa_05', 3023674],
['Chile-Equipamentos-Empresa_14', 2917403],
['Brasil-Equipamentos-Empresa_05', 2795837],
['Brasil-Reagentes-Empresa_51', 2793680],
['Peru-Equipamentos-Empresa_05', 2790679],
['Peru-Reagentes-Empresa_51', 2788526],
['Brasil-Equipamentos-Empresa_06', 2594373],
['Argentina-Serviços-Empresa_29', 2291700],
['México-Serviços-Empresa_29', 2286149],
['Argentina-Equipamentos-Empresa_29', 2214734],
['México-Equipamentos-Empresa_29', 2209369],
['Chile-Serviços-Empresa_14', 2072846],
['Brasil-Serviços-Empresa_29', 1952093],
['Peru-Serviços-Empresa_29', 1948492],
['Chile-Serviços-Empresa_05', 1914981],
['Brasil-Equipamentos-Empresa_29', 1886533],
['Peru-Equipamentos-Empresa_29', 1883053],
['Argentina-Serviços-Empresa_08', 1796895],
['México-Serviços-Empresa_08', 1792542],
['Colômbia-Serviços-Empresa_08', 1655345],
['México-Equipamentos-Empresa_01', 1650078],
['Argentina-Equipamentos-Empresa_14', 1586022],
['México-Equipamentos-Empresa_14', 1582180],
['Brasil-Serviços-Empresa_08', 1530613],
['Peru-Serviços-Empresa_08', 1527789],
['Colômbia-Equipamentos-Empresa_14', 1461084],
['Brasil-Equipamentos-Empresa_01', 1408966],
['Brasil-Equipamentos-Empresa_47', 1406765],
['México-Equipamentos-Empresa_28', 1398090],
['México-Serviços-Empresa_10', 1390840],
['Brasil-Equipamentos-Empresa_14', 1350990],
['Peru-Equipamentos-Empresa_14', 1348498],
['México-Serviços-Empresa_33', 1315806],
['México-Equipamentos-Empresa_23', 1218040],
['Brasil-Equipamentos-Empresa_28', 1193799],
['Brasil-Serviços-Empresa_10', 1187609],
['Argentina-Serviços-Empresa_14', 1126886],
['México-Serviços-Empresa_14', 1124156],
['Brasil-Serviços-Empresa_33', 1123538],
['Argentina-Equipamentos-Empresa_53', 1116386],
['México-Equipamentos-Empresa_53', 1113682],
['Argentina-Serviços-Empresa_05', 1041064],
['Brasil-Equipamentos-Empresa_23', 1040058],
['México-Serviços-Empresa_05', 1038541],
['Colômbia-Serviços-Empresa_14', 1038116],
['Brasil-Serviços-Empresa_14', 959892],
['Colômbia-Serviços-Empresa_05', 959054],
['Peru-Serviços-Empresa_14', 958122],
['Brasil-Equipamentos-Empresa_53', 950949],
['México-Serviços-Empresa_26', 933752],
['México-Equipamentos-Empresa_34', 923465],
['Brasil-Serviços-Empresa_05', 886788],
['Peru-Serviços-Empresa_05', 885152],
['México-Amostras-Empresa_26', 810322],
['Brasil-Serviços-Empresa_26', 797311],
['Brasil-Equipamentos-Empresa_34', 788527],
['México-Equipamentos-Empresa_33', 780124],
['México-Serviços-Empresa_06', 694953],
['Brasil-Amostras-Empresa_26', 691916],
['Brasil-Equipamentos-Empresa_33', 666131],
['México-Serviços-Empresa_28', 643126],
['Argentina-Serviços-Empresa_09', 598045],
['México-Serviços-Empresa_09', 596596],
['Brasil-Serviços-Empresa_06', 593405],
['Brasil-Serviços-Empresa_28', 549151],
['Brasil-Serviços-Empresa_09', 509420],
['Brasil-Serviços-Empresa_47', 361852],
['Argentina-Serviços-Empresa_53', 258567],
['México-Serviços-Empresa_53', 257941],
['Argentina-Equipamentos-Empresa_27', 231326],
['México-Equipamentos-Empresa_27', 230766],
['Argentina-Equipamentos-Empresa_30', 220344],
['Brasil-Serviços-Empresa_53', 220250],
['México-Equipamentos-Empresa_30', 219810],
['Colômbia-Equipamentos-Empresa_27', 213103],
['Argentina-Equipamentos-Empresa_57', 198612],
['México-Equipamentos-Empresa_57', 198131],
['Brasil-Equipamentos-Empresa_27', 197046],
['Peru-Equipamentos-Empresa_27', 196682],
['Brasil-Equipamentos-Empresa_30', 187691],
['México-Serviços-Empresa_01', 170037],
['Brasil-Equipamentos-Empresa_57', 169180],
['Peru-Equipamentos-Empresa_57', 168868],
['Brasil-Serviços-Empresa_01', 145191],
['Argentina-Equipamentos-Empresa_51', 91588],
['México-Equipamentos-Empresa_51', 91366],
['Brasil-Equipamentos-Empresa_51', 78016],
['Peru-Equipamentos-Empresa_51', 77872],
['Argentina-Serviços-Empresa_30', 51167],
['México-Serviços-Empresa_30', 51043],
['México-Amostras-Empresa_33', 46598],
['Brasil-Serviços-Empresa_30', 43585],
['Brasil-Amostras-Empresa_33', 39789],
['México-Serviços-Empresa_23', 36116],
['Brasil-Serviços-Empresa_23', 30838],
['Argentina-Serviços-Empresa_51', 29449],
['México-Serviços-Empresa_51', 29378],
['Brasil-Serviços-Empresa_51', 25085],
['Peru-Serviços-Empresa_51', 25039],
['Chile-Amostras-Empresa_14', 6120],
['Argentina-Amostras-Empresa_14', 3327],
['México-Amostras-Empresa_14', 3319],
['Colômbia-Amostras-Empresa_14', 3065],
['México-Serviços-Empresa_34', 2918],
['Brasil-Amostras-Empresa_14', 2834],
['Peru-Amostras-Empresa_14', 2828],
['Brasil-Serviços-Empresa_34', 2492],
['Argentina-Serviços-Empresa_57', 219],
['México-Serviços-Empresa_57', 218],
['Brasil-Serviços-Empresa_57', 186],
['Peru-Serviços-Empresa_57', 186],
 ])}
