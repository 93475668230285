


import {
    Container,
    Heading,
    Text
} from "@chakra-ui/react";



export default function SectionHeader({ supHeader, mainHeader, subHeader, negative }: { supHeader?: string, mainHeader: string, subHeader?: string,negative?:boolean }) {


    return (



        <Container maxW='100%'  py="28px" textAlign="left">

            <Text fontSize='md' color='in3.500' fontWeight='bold' display={supHeader?"initial":"none"}>{supHeader}</Text>
            <Heading fontSize='4xl' color={negative?'#FFF':"initial"} >{mainHeader}</Heading>
            <br />
            <Text fontSize='lg' color={negative?'#FFF':"initial"} fontFamily='montserrat'  fontWeight='300'  >{subHeader}</Text>



        </Container >

    );
};








