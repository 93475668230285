


import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Card,
    CardBody,
    CardHeader,
    Heading,
    ListItem,
    UnorderedList
} from "@chakra-ui/react";

import { Box } from "@chakra-ui/react";


export default function RoadmapCard({ cycleName, RoadMapDetails }: { cycleName: string, RoadMapDetails: any }) {

    return (

        <Card h='100%' >

            <CardHeader>
                <Heading size='md'> {cycleName}</Heading>
            </CardHeader>
            <CardBody px='0px'>




                <Accordion defaultIndex={[0, 1, 2, 3]} allowMultiple bg='#F1F1FA'>

                    {RoadMapDetails.map((details: any, index: any) => (
                        <AccordionItem key={index}  >
                            <h2>
                                <AccordionButton _expanded={{ bg: 'in3.50', color: 'white' }}>
                                    <Box as="span" flex='1' textAlign='left'>
                                        {details.title}
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel minH={details.title == "Fonte dos dados" ? "200px" : "100px"} pb={4}>
                                <UnorderedList>
                                    {details.content.map((content: any, index: any) => (
                                        <ListItem key={index}>
                                            {content}
                                        </ListItem>
                                    ))}
                                </UnorderedList>
                            </AccordionPanel>
                        </AccordionItem>

                    ))}

                </Accordion>
            </CardBody>

        </Card >


    );
};
















