import {
    Box,
    Flex,
    HStack,
    Image,
    useColorMode
} from '@chakra-ui/react'
import CTAButton from '../CTAButton'
import FlagChanger from '../FlagChanger'


export default function Navbar() {
    const { colorMode, toggleColorMode } = useColorMode()
    return (

        <Flex px={{ base: "1%", md: "7%" }} h='110px' align='center' justifyContent={'space-between'}>
            <Image ml={{ base: "1%", md: "5%" }} src='Asset-3.png' w='100px' h='54px' alt='' />
            <Box>
                <HStack spacing='16px'>

                    <CTAButton />
                    <FlagChanger />
                </HStack>
            </Box>
        </Flex>

    )
}