export const content_es = () => ({
    "ConceptCardList": [
        {
            "title": "GO/NO GO",
            "subtitle": "Toma de decisiones basada en datos",
            "content": "Evaluar escenarios y oportunidades de mercado en países de América Latina, realizar análisis comparativos y tomar decisiones de inversión basadas en datos estratégicos sobre el potencial del mercado y las acciones de los competidores.",
            "icon": "GO/NO GO",
            "color": "purple"
        },
        {
            "title": "GO-TO-MARKET",
            "subtitle": "Planificación informada y dirigida para la innovación",
            "content": "Analizar tendencias, proyecciones y movimientos del mercado para planificar lanzamientos de productos y la introducción de estrategias comerciales en países de América Latina, de acuerdo con sus características específicas.",
            "icon": "GOTOMARKET",
            "color": "yellow"
        },
        {
            "title": "MARKET EXPANSION",
            "subtitle": "Análisis estratégico para el crecimiento y consolidación del mercado",
            "content": "Explorar oportunidades de crecimiento en los territorios donde opera su empresa, identificando demandas, nuevos nichos y brechas competitivas sin explorar.",
            "icon": "MARKET EXPANSION",
            "color": "red"
        }
    ],
    "HomepageTextblock": {
        "header": "Bienvenido al primer Proyecto de Inteligencia Integrada para el Mercado de IVD en América Latina",
        "body": ["El objetivo de este proyecto es generar datos e información precisos y en profundidad para respaldar la planificación comercial y de marketing estratégica de las empresas que operan en el mercado de IVD en América Latina, aumentando así la competitividad general de este mercado.", "Para lograrlo, se emplearán metodologías y herramientas de Big Data, Ciencia de Datos e Inteligencia Artificial para empoderar a los profesionales del mercado con información estratégica, con el objetivo de lograr precisión en la planificación y la toma de decisiones."]
    },
    "QuestionCardList": [
        {
            "title": "Tendencias",
            "subtitle": "Mercado General",
            "question": "¿Cuál es el potencial y las tendencias del mercado de IVD en América Latina?",
            "frequency": "Informe Anual",
            "icon": "Trends"
        },
        {
            "title": "Participación",
            "subtitle": "Productos",
            "question": "¿Cuáles son los niveles de participación en el mercado de los actores de IVD?",
            "frequency": "Informe Mensual",
            "icon": "Share"
        },
        {
            "title": "Tamaño",
            "subtitle": "Mercado General",
            "question": "¿Cuál es el tamaño del mercado de IVD en los países de América Latina?",
            "frequency": "Informe Mensual",
            "icon": "Size"
        },
        {
            "title": "Procedimientos",
            "subtitle": "Procedimientos",
            "question": "¿Cuál es la proporción de procedimientos realizados en América Latina?",
            "frequency": "Informe Mensual",
            "icon": "Procedures"
        },
        {
            "title": "Objetivos",
            "subtitle": "Establecimientos",
            "question": "¿Cuál es el perfil de los establecimientos de atención médica de IVD en América Latina?",
            "frequency": "Informe Mensual",
            "icon": "Targets"
        },
        {
            "title": "Segmentos",
            "subtitle": "Canales",
            "question": "¿Cómo se segmenta la venta y distribución de productos de IVD en América Latina?",
            "frequency": "Informe Mensual",
            "icon": "Segments"
        }
    ],
    "RoadmapCardList": [
        {
            "cycleName": "Primer Ciclo", "RoadMapDetails": [
                { "title": "Ideas", "content": ["Tamaño y evolución de los mercados generales"] },
                { "title": "Fuente de Datos", "content": ["Importaciones por país", "Datos compartidos"] },
                { "title": "Cuota de Membresía", "content": ["Cuota de membresía: $500.00 al mes por país."] },
                { "title": "Beneficios", "content": ["Datos realistas y confiables del mercado de IVD"] },
            ]
        },
        {
            "cycleName": "Segundo Ciclo", "RoadMapDetails": [
                { "title": "Ideas", "content": ["Tamaño y evolución de mercados específicos"] },
                { "title": "Fuente de Datos", "content": ["Importaciones por país", "Datos compartidos", "Procedimientos por institución"] },
                { "title": "Cuota de Membresía", "content": ["Por definir según el número de participantes"] },
                { "title": "Beneficios", "content": ["Análisis específicos de líneas y sublíneas de productos"] },
            ]
        },
        {
            "cycleName": "Tercer Ciclo", "RoadMapDetails": [
                { "title": "Ideas", "content": ["Perfil del público objetivo"] },
                { "title": "Fuente de Datos", "content": ["Importaciones por país", "Datos compartidos", "Procedimientos por institución", "Establecimientos por ciudad"] },
                { "title": "Cuota de Membresía", "content": ["Por definir según el número de participantes"] },
                { "title": "Beneficios", "content": ["Análisis para la Inteligencia Empresarial"] },
            ]
        },
        {
            "cycleName": "Cuarto Ciclo", "RoadMapDetails": [
                { "title": "Ideas", "content": ["Criterios de decisión de compra y evaluación de la base instalada"] },
                { "title": "Fuente de Datos", "content": ["Importaciones por país", "Datos compartidos", "Procedimientos por institución", "Establecimientos por ciudad", "Datos primarios (investigación de mercado)"] },
                { "title": "Cuota de Membresía", "content": ["Por definir según el número de participantes"] },
                { "title": "Beneficios", "content": ["Análisis detallado del público objetivo y tendencias de consumo"] },
            ]
        },
    ],
    "RoadmapStep": [
        { "description": "Inicio del Proyecto", "title": "Octubre / 2023" },
        { "description": "", "title": "Enero / 2024" },
        { "description": "", "title": "Abril / 2024" },
        { "description": "", "title": "Julio / 2024" },
    ],
    "CTAButton": {
        "buttonText": "Acceder a la demostración",
        "ModalHeader": "Información de Acceso",
        "modalbody": "Para acceder a la demostración del panel de Inteligencia LATAM, utilice la siguiente información:",
        "username": "Nombre de Usuario:",
        "password": "Contraseña:",
        "cta": "Acceder",
    },
    "GlobeHero": {
        "gdp": "PIB:",
        "pop": "Población:"
    },
    "SunburstHero": {
        "absoluteText": "Tamaño total del mercado analizado",
        "percentageText": "Cuota de mercado del segmento seleccionado",
        "Argentina": "Argentina",
        "Brasil": "Brasil",
        "Chile": "Chile",
        "Colômbia": "Colombia",
        "México": "México",
        "Peru": "Perú",
        "Empresa_01": "Empresa 01",
        "Empresa_02": "Empresa 02",
        "Empresa_05": "Empresa 05",
        "Empresa_06": "Empresa 06",
        "Empresa_07": "Empresa 07",
        "Empresa_08": "Empresa 08",
        "Empresa_09": "Empresa 09",
        "Empresa_10": "Empresa 10",
        "Empresa_14": "Empresa 14",
        "Empresa_23": "Empresa 23",
        "Empresa_26": "Empresa 26",
        "Empresa_27": "Empresa 27",
        "Empresa_28": "Empresa 28",
        "Empresa_29": "Empresa 29",
        "Empresa_30": "Empresa 30",
        "Empresa_33": "Empresa 33",
        "Empresa_34": "Empresa 34",
        "Empresa_47": "Empresa 47",
        "Empresa_51": "Empresa 51",
        "Empresa_53": "Empresa 53",
        "Empresa_54": "Empresa 54",
        "Empresa_56": "Empresa 56",
        "Empresa_57": "Empresa 57",
        "Amostras": "Muestras",
        "Equipamentos": "Equipos",
        "Reagentes": "Reactivos",
        "Serviços": "Servicios"
    },
    "UseCases":
    {
        "Text_1": "Casos de uso de la plataforma",
        "Text_2": "Tomar decisiones estratégicas basadas en datos",
    },
    "RoadMap":
    {
        "Text_1": "El plan de desarrollo",
        "Text_2": "Cómo construiremos el proyecto",
        "Text_3": "El proyecto se desarrollará de forma modular. Con cada ciclo, se incorporarán nuevas bases de datos para ampliar el alcance de los análisis y proporcionar constantemente más y nuevas ideas. En este primer ciclo, comenzaremos con los siguientes países: Argentina, Brasil, Chile, Colombia, México y Perú, expandiéndonos progresivamente a medida que avance el proyecto.",
    },
    "MarketSize":
    {
        "Text_1": "Series temporales",
        "Text_2": "Evolución del tamaño del mercado de diagnóstico in vitro en América Latina",
        "Text_3": "Comprender el contexto y las tendencias del mercado",
        "Text_4": "marketSize_en.png"
    },
    "MarketShare":
    {
        "Text_1": "En la práctica",
        "Text_2": "Cómo funcionan los análisis",
        "Text_3": "Algunas demostraciones de cómo se presentan las ideas",
        "Text_4": "Análisis dimensional",
        "Text_5": "Cuota de mercado: Diagnóstico in vitro en América Latina",
        "Text_6": "Analizar el mercado de IVD en América Latina en su conjunto",
    },
    "KeyQuestions":
    {
        "Text_1": "Obtener más información",
        "Text_2": "Preguntas que deben responderse",
        "Text_3": "Vea algunas ideas que los análisis pueden proporcionar para su negocio",
    },
    "Companies":
    {
        "Text_1": "EMPRESAS INTELIGENTES"
    }
}
)