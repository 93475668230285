import { Box } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import SunburstHero from "./SunburstHero";


export default function SunburstContainer() {

    const sunburst = useRef<HTMLDivElement | null>(null);

    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
    const [key, setKey] = useState(0);



    // const sunburst = useCallback((node:any) => {
    //     if (node !== null) {
    //         setDimensions({
    //             width: node.getBoundingClientRect().width,
    //             height: node.getBoundingClientRect().height
    //         });
    //     }
    // }, []);

    function handleDimensions(){
        if (sunburst.current) {
            setDimensions({
                width: sunburst.current.offsetWidth,
                height: sunburst.current.offsetWidth
            });
        }
    }


    useEffect(() => {
        handleDimensions()

        window.addEventListener("resize",handleDimensions, false);

    }, [sunburst, window]);

    return (
        <Box ref={sunburst} w={{ base: "100%", md: "80%" }} h="100%" >
            <Box  w="100%" h="100%" >
                <SunburstHero width={dimensions.width} height={dimensions.height} />
            </Box>
        </Box>

    );
};








