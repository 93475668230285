
import { Box, Center, Text, VStack } from '@chakra-ui/react';
import * as d3 from "d3";
import { useEffect, useRef, useState } from "react";
import i18n_selector from '../../../I18N';
import Markets from '../../../markets';

import { useSelector } from 'react-redux';


export default function SunburstHero({ width, height }: { width: number, height: number }) {




    function currencyFormatter(num: any) {
        const lookup = [
            { value_ceil: 1, value: 1, symbol: "", decimal_points: 2 },
            { value_ceil: 1, value: 1e1, symbol: "", decimal_points: 1 },
            { value_ceil: 1, value: 1e2, symbol: "", decimal_points: 0 },
            { value_ceil: 1e3, value: 1e3, symbol: " K", decimal_points: 1 },
            { value_ceil: 1e3, value: 1e4, symbol: " K", decimal_points: 1 },
            { value_ceil: 1e3, value: 1e5, symbol: " K", decimal_points: 0 },
            { value_ceil: 1e6, value: 1e6, symbol: " M", decimal_points: 1 },
            { value_ceil: 1e6, value: 1e7, symbol: " M", decimal_points: 1 },
            { value_ceil: 1e6, value: 1e8, symbol: " M", decimal_points: 0 },
            { value_ceil: 1e9, value: 1e9, symbol: " B", decimal_points: 2 },
            { value_ceil: 1e9, value: 1e10, symbol: " B", decimal_points: 1 },
            { value_ceil: 1e9, value: 1e11, symbol: " B", decimal_points: 0 },
            { value_ceil: 1e12, value: 1e12, symbol: " Trilhões", decimal_points: 1 },
            { value_ceil: 1e15, value: 1e15, symbol: " Quatrilhões", decimal_points: 1 }
        ];
        const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
        var item = lookup
            .slice()
            .reverse()
            .find(function (item) {
                return Math.abs(num) >= item.value;
            });
        var returnable = item
            ? (Math.abs(num) / item.value_ceil).toFixed(item.decimal_points) +
            item.symbol
            : "0";
        var negative_sign = num < 0 ? "-" : "";
        var currency = "$";

        return negative_sign + currency + returnable;
    }


    function getAncestors(node: any) {
        var path = [];
        var current = node;
        while (current.parent) {
            path.unshift(current);
            current = current.parent;
        }
        return path;
    }


    function getColors(d: any) {


        var ancestral_color = getAncestors(d)[0];


        if (ancestral_color) return colors[ancestral_color.data.name];
        else return colors[d.data.name];

    }



    function buildHierarchy(csv: any) {
        var root: any = { "name": "root", "children": [] };
        for (var i = 0; i < csv.length; i++) {
            var sequence = csv[i][0];
            var size = +csv[i][1];
            if (isNaN(size)) { // e.g. if this is a header row
                continue;
            }
            var parts = sequence.split("-");
            var currentNode = root;
            for (var j = 0; j < parts.length; j++) {
                var children = currentNode["children"];
                var nodeName = parts[j];
                var childNode;
                if (j + 1 < parts.length) {
                    // Not yet at the end of the sequence; move down the tree.
                    var foundChild = false;
                    for (var k = 0; k < children.length; k++) {
                        if (children[k]["name"] == nodeName) {
                            childNode = children[k];
                            foundChild = true;
                            break;
                        }
                    }
                    // If we don't already have a child node for this branch, create it.
                    if (!foundChild) {
                        childNode = { "name": nodeName, "children": [] };
                        children.push(childNode);
                    }
                    currentNode = childNode;
                } else {
                    // Reached the end of the sequence; create a leaf node.
                    childNode = { "name": nodeName, "size": size };
                    children.push(childNode);
                }
            }
        }
        return root;
    };


    // Fade all but the current sequence, and show it in the breadcrumb trail.
    function mouseover(d: any) {

        var percentage: number = (100 * d.value / totalSize);
        var percentageString = percentage.toPrecision(2) + "%";
        if (percentage < 0.1) {
            percentageString = "< 0.1%";
        }

        d3
            .select(valueRef.current)
            .text(percentageString);

        d3
            .select(centerTextRef.current)
            .text(pageText['percentageText']);


        var sequenceArray = getAncestors(d);
        updateBreadcrumbs(sequenceArray, percentageString);

        // Fade all the segments.
        d3.selectAll("path")
            .style("opacity", 0.3);

        // Then highlight only those that are an ancestor of the current segment.
        d3
            .select(containerRef.current)
            .selectAll("path")
            .filter(function (node: any) {
                return (sequenceArray.indexOf(node) >= 0);
            })
            .style("opacity", 1);
    }

    function setValue(value: any) {
        var absoluteValue = currencyFormatter(value);
        var absoluteString = absoluteValue;

        d3
            .select(valueRef.current)
            .text(absoluteString);

        d3
            .select(centerTextRef.current)
            .text(pageText['absoluteText']);
    }



    // Restore everything to full opacity when moving off the visualization.
    function mouseleave(d: any) {

        setValue(root.value);

        // Hide the breadcrumb trail
        d3
            .select(trailRef.current)
            .style("visibility", "hidden");

        // Deactivate all segments during transition.
        d3.selectAll("path").on("mouseover", null);

        // Transition each segment to full opacity and then reactivate it.
        d3.selectAll("path")
            .transition()
            .duration(1)
            .style("opacity", 1)
            .each(function () {
                d3.select(this).on("mouseover", (d: any) => { mouseover(d.target.__data__) });
            });


    }

    function initializeBreadcrumbTrail() {
        // Add the svg area.
        var trail = d3
            .select(sequenceRef.current)
            .append("svg:svg")
            .attr("width", width)
            .attr("height", 75)
            .attr("id", "trail");

        trailRef.current = trail.node()


        // Add the label at the end, for the percentage.
        var endlabel = trail.append("svg:text")
            .attr("id", "endlabel")
            .style("fill", "#000");

        endlabelRef.current = endlabel.node()
    }


    // Generate a string that describes the points of a breadcrumb polygon.
    function breadcrumbPoints(d: any, i: any) {
        var points = [];
        points.push("0,0");
        points.push(b.w + ",0");
        points.push(b.w + b.t + "," + (b.h / 2));
        points.push(b.w + "," + b.h);
        points.push("0," + b.h);
        if (i > 0) { // Leftmost breadcrumb; don't include 6th vertex.
            points.push(b.t + "," + (b.h / 2));
        }
        return points.join(" ");
    }

    // Update the breadcrumb trail to show the current sequence and percentage.
    function updateBreadcrumbs(nodeArray: any, percentageString: any) {


        // Data join; key function combines name and depth (= position in sequence).
        d3.select(trailRef.current)
            .selectAll("g").remove()

        var g = d3
            .select(trailRef.current)
            .selectAll("g")
            .data(nodeArray, function (d: any) { return d.data.name + d.data.depth; });


        // Add breadcrumb and label for entering nodes.
        var entering = g.enter().append("svg:g");


        // Remove exiting nodes.
        entering.exit().remove();

        entering.append("polygon")
            .attr("points", breadcrumbPoints)
            .style("fill", getColors);



        entering.append("svg:text")
            .attr("x", (b.w + b.t) / 2)
            .attr("y", b.h / 2)
            .attr("dy", "0.35em")
            .attr("text-anchor", "middle")
            .text(function (d: any) { return pageText[d.data.name as keyof typeof pageText]; });



        // Set position for entering and updating nodes.
        entering.attr("transform", function (d, i) {
            return "translate(" + i * (b.w + b.s) + ", 0)";
        });

        // Remove exiting nodes.
        entering.exit().remove();


        // Now move and update the percentage at the end.
        d3
            .select(endlabelRef.current)
            .attr("x", (nodeArray.length + 0.3) * (b.w + b.s))
            .attr("y", b.h / 2)
            .attr("dy", "0.35em")
            .attr("text-anchor", "middle")
            .text(percentageString);



        // Make the breadcrumb trail visible, if it's hidden.
        d3
            .select(trailRef.current)
            .style("visibility", "");

    }


    // Main function to draw and set up the visualization, once we have the data.
    function createVisualization(root: any, svg: any) {


        // Basic setup of page elements.
        initializeBreadcrumbTrail();

        // Bounding circle underneath the sunburst, to make it easier to detect
        // when the mouse leaves the parent g.
        svg.append("svg:circle")
            .attr("r", radius)
            .style("opacity", 0);



        var path =

            svg.selectAll("path")
                .data(root.descendants())
                .enter()
                .append("svg:path")
                .attr("display", function (d: any) { return d.depth ? null : "none"; })
                .attr("d", arc)
                .attr("fill-rule", "evenodd")
                .style('stroke', '#FFF')  // <-- 7
                .style("fill", getColors)
                .style("opacity", 1)
                .on("mouseover", (d: any) => { mouseover(d.target.__data__) });

        // Add the mouseleave handler to the bounding circle.
        d3
            .select(containerRef.current)
            .on("mouseleave", (d: any) => { mouseleave(d) });

        // Get total size of the tree = value of root node from partition.
        totalSize = root.value as number


        setValue(totalSize);


    };


    const sequenceRef = useRef<any | null>(null);
    const chartRef = useRef<any | null>(null);
    const explanationRef = useRef<any | null>(null);
    const valueRef = useRef<any | null>(null);
    const centerTextRef = useRef<any | null>(null);
    const containerRef = useRef<any | null>(null);
    const trailRef = useRef<any | null>(null);
    const endlabelRef = useRef<any>(null);



    // var width = 750;
    // var height = 600;
    var radius = Math.min(width, height) / 2;

    var lang = useSelector((state: any) => state.langs)

    const pageText: any = i18n_selector(useSelector((state: any) => state.langs))['SunburstHero']


    // Breadcrumb dimensions: width, height, spacing, width of tip/tail.
    var b = {
        w: width / 4, h: height / 12, s: 5, t: 10
    };

    // Mapping of step names to colors.
    var colors: any = {
        "Argentina": "#6A8DF3",
        "Brasil": "#FF677D",
        "Chile": "#FFD966",
        "Colômbia": "#4ECDC4",
        "México": "#F6AD55",
        "Peru": "#7B9EA8",
        "Empresa_01": "#5687d1",
        "Empresa_02": "#5687d1",
        "Empresa_05": "#5687d1",
        "Empresa_06": "#5687d1",
        "Empresa_07": "#5687d1",
        "Empresa_08": "#5687d1",
        "Empresa_09": "#5687d1",
        "Empresa_10": "#5687d1",
        "Empresa_14": "#5687d1",
        "Empresa_23": "#5687d1",
        "Empresa_26": "#5687d1",
        "Empresa_27": "#5687d1",
        "Empresa_28": "#5687d1",
        "Empresa_29": "#5687d1",
        "Empresa_30": "#5687d1",
        "Empresa_33": "#5687d1",
        "Empresa_34": "#5687d1",
        "Empresa_47": "#5687d1",
        "Empresa_51": "#5687d1",
        "Empresa_53": "#5687d1",
        "Empresa_54": "#5687d1",
        "Empresa_56": "#5687d1",
        "Empresa_57": "#5687d1",
        "Amostras": "#5687d1",
        "Equipamentos": "#5687d1",
        "Reagentes": "#5687d1",
        "Serviços": "#5687d1"
    };


    // Total size of all segments; we set this later, after loading the data.
    var totalSize = 1;

    var csv = Markets();

    var json = buildHierarchy(csv);

    var root = d3.hierarchy(json)  // <-- 1
        .sum(function (d) { return d.size });  // <-- 2


    var partition = d3.partition().size([2 * Math.PI, radius * radius])

    partition((root as d3.HierarchyNode<unknown>))

    var arc: any = d3.arc()
        .startAngle(function (d: any) { return d.x0; })
        .endAngle(function (d: any) { return d.x1; })
        .innerRadius(function (d: any) { return Math.sqrt(d.y0); })
        .outerRadius(function (d: any) { return Math.sqrt(d.y1); });

    var svg: any =
        d3
            .select(chartRef.current)
            .append("svg:svg")
            .attr("width", width)
            .attr("height", height)
            .append("svg:g")
            .attr("id", "chartContainer")
            .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");

    containerRef.current = svg.node()

    createVisualization(root, svg)


    function handleWindowSizeChange() {
        d3.select(chartRef.current).selectAll("*").remove();
        d3.select(sequenceRef.current).selectAll("*").remove();
        d3.select(chartRef.current).selectAll("*").remove();
        // d3.select(explanationRef.current).selectAll("*").remove();
        // d3.select(valueRef.current).selectAll("*").remove();
        // d3.select(centerTextRef.current).selectAll("*").remove();
        d3.select(containerRef.current).selectAll("*").remove();
        d3.select(trailRef.current).selectAll("*").remove();
        d3.select(endlabelRef.current).selectAll("*").remove();
    }
    const [count, setCount] = useState(0);


    useEffect(() => {
        var counter = count+1
        setCount(counter)
        
        if (counter > 0) {
            setCount(0)
            console.log(counter)
            handleWindowSizeChange()
        }
    }, [lang]);

    useEffect(() => {

        // containerRef.current
        // .attr("width", width)
        // .attr("height", height)
        // containerRef.current

        // trailRef.current
        //     .attr("width", width)
        //     .attr("height", 75)

        window.addEventListener('resize', handleWindowSizeChange);
        // pageText.addEventListener('resize', handleWindowSizeChange);

        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };



    }, [window.onresize]);

    return (
        <div>
            <Box fontSize={{ base: "0.75em", md: "1.25em" }} ref={sequenceRef}></Box>
            <Box display='grid' maxW={width} maxH={height}>

                <Box ref={explanationRef} h='100%' w='100%' gridColumn={1} gridRow={1} >
                    <Center h='100%'>
                        <VStack maxW='30%' textAlign={"center"}>
                            <Text fontSize={{ base: "1.5rem", xl: "2.0rem", "2xl": "2.5rem" }} ref={valueRef}></Text>
                            <Text fontSize={{ base: "0.9rem", xl: "1.2rem", "2xl": "1.5rem" }} ref={centerTextRef}></Text>
                        </VStack  >
                    </Center>
                </Box>
                <Box ref={chartRef} h='100%' w='100%' gridColumn={1} gridRow={1}>
                </Box>
            </Box>
        </div >

    );
};


