import {
    Box,
    Step,
    StepDescription,
    StepIcon,
    StepIndicator,
    StepNumber,
    StepSeparator,
    StepStatus,
    StepTitle,
    Stepper,
    Text,
    useSteps
} from "@chakra-ui/react";
import { useSelector } from 'react-redux';

import i18n_selector from "../../I18N";

export default function RoadmapStep() {

    const steps:any = i18n_selector(useSelector((state: any) => state.langs))['RoadmapStep']

    const { activeStep, setActiveStep } = useSteps({
        index: 0,
        count: steps.length,
    })

    return (
        <Stepper index={activeStep}>
            {steps.map((step:any, index:any) => (
                <Step key={index} onClick={() => setActiveStep(index)}>
                    <StepIndicator>
                        <StepStatus
                            complete={<StepIcon />}
                            incomplete={<StepNumber />}
                            active={<StepNumber />}
                        />
                    </StepIndicator>

                    <Box flexShrink='0'>
                        <StepTitle>{step.title}</StepTitle>
                        <Text fontSize="sm" as='div'>
                            <StepDescription >{step.description}</StepDescription>
                        </Text>
                    </Box>

                    <StepSeparator />
                </Step>
            ))}
        </Stepper>
    )






    // return (

    //     <Grid templateColumns='repeat(3, 1fr)' gap={5} pt='25px'>
    //         {cardList.map((cardInfo, index) => (
    //             <Center key={index}>
    //                 <GridItem maxW='80%' h='100%' colSpan={1}>
    //                     <QuestionCard {...cardInfo} />
    //                 </GridItem>
    //             </Center>
    //         ))}

    //     </Grid>

    // )
}


