import {
    Center,
    Grid,
    GridItem
} from "@chakra-ui/react";
import ConceptCard from "./ConceptCard";

import { useSelector } from 'react-redux';

import i18n_selector from "../../I18N";


export default function ConceptCardList() {

    
    const cardList:any = i18n_selector(useSelector((state: any) => state.langs))['ConceptCardList']


    return (

        <Grid templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(3, 1fr)"}} gap={10} pb='50px'>
            {cardList.map((cardInfo:any, index:any) => (
                <Center key={index}>
                    <GridItem  h='100%' colSpan={1}>
                        <ConceptCard {...cardInfo} />
                    </GridItem>
                </Center>
            ))}

        </Grid>

    )
}


