import {
    Box,
    Center
} from "@chakra-ui/react";
import ConceptCardList from "../../components/ConceptCardList";
import SectionHeader from "../../components/SectionHeader";

import i18n_selector from "../../I18N";

import { useSelector } from 'react-redux';


export const UseCases = () => {
    const cardList: any = i18n_selector(useSelector((state: any) => state.langs))['UseCases']

    return (

        <Box px='5%' bgGradient='linear(to-r, in3.100, in3.200)'>
            <Box>
                <SectionHeader
                    mainHeader={cardList["Text_1"]}
                    subHeader={cardList["Text_2"]} negative />
            </Box>
            <Box>
                <Center >
                    <ConceptCardList />
                </Center>
            </Box>
        </Box>

    )
}




