import { extendTheme } from '@chakra-ui/react';

import '@fontsource-variable/inter';
import '@fontsource-variable/montserrat';




const theme = extendTheme({


  fonts: {
    heading:`'Inter Variable', sans-serif`,
    body:`'Inter Variable', sans-serif`,
    inter: `'Inter Variable', sans-serif`,
    montserrat: `'Montserrat Variable', sans-serif`,
  },
  colors: {
    in3: {
      50: '#12486d',
      100: "#1482ac",
      200: "#1b3c49",
      300: '#144C7A',
      400: "#F36532",
      500: '#2c649b',
      800: '#5B5A62',
      900: '#777777',
    }
  }

})

export default theme