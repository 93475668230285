
import { content_en } from "./locales/en";
import { content_es } from "./locales/es";
import { content_pt } from "./locales/pt";


export default function I18n_selector(language: string) {

  if (language === 'pt-BR') {
    return content_pt();
  }
  else if (language === 'en-US') {
    return content_en();
  }
  else if (language === 'es-AR') {
    return content_es();
  }
  else if (language === 'es-CL') {
    return content_es();
  }
  else if (language === 'es-CO') {
    return content_es();
  }
  else if (language === 'es-MX') {
    return content_es();
  }
  else if (language === 'es-PE') {
    return content_es();
  }
  else {
    return content_en();
  }
}


