import {
    Box,
    Card,
    CardBody,
    Center,
    Grid,
    GridItem,

    Image
} from "@chakra-ui/react";
import FeatureCard from "../../components/FeatureCard";

import { useSelector } from 'react-redux';
import i18n_selector from "../../I18N";


export const MarketSize = () => {
    const cardList:any = i18n_selector(useSelector((state: any) => state.langs))['MarketSize']   

    return(
    <Box bg='in3.300' py='5%' mt='5%' bgGradient='linear(to-r, in3.100, in3.200)'>

        <Grid templateColumns='repeat(12, 1fr)' gap={0} px='5%'>
            <GridItem w='90%' h='auto' colSpan={{ base: 12, md: 6}} pr='2%'>
                <Center h='100%' >
                    <FeatureCard
                        supHeader={cardList['Text_1']}
                        mainHeader={cardList['Text_2']}
                        subHeader={cardList['Text_3']}
                        variant="negative"
                    />
                </Center>
            </GridItem>
            <GridItem w='100%' h='auto' colSpan={{ base: 12, md: 6}}>
                <Card w='100%'>
                    <CardBody>
                        <Center  >
                            <Image src={cardList['Text_4']} w='100%' alt='' />
                        </Center>
                    </CardBody>
                </Card>



            </GridItem>
        </Grid>
    </Box>
)}


