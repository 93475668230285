


import {
    Image
} from "@chakra-ui/react";







export default function CompaniesCard({ companyInfo }: { companyInfo: any }) {



    return (

        <Image src={companyInfo.companyLogo} style={{
            WebkitFilter: "grayscale(1) brightness(120%) blur(3px)"
            
        }} alt='' />

    )



};


// ,













