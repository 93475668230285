import {
    Box
} from "@chakra-ui/react";
import { useSelector } from 'react-redux';
import QuestionCardList from "../../components/QuestionCardList";
import SectionHeader from "../../components/SectionHeader";

import i18n_selector from "../../I18N";



export const KeyQuestions = () => {
    const cardList: any = i18n_selector(useSelector((state: any) => state.langs))['KeyQuestions']
    return (
        <Box >
            <Box >
                <SectionHeader
                    supHeader={cardList['Text_1']}
                    mainHeader={cardList['Text_2']}
                    subHeader={cardList['Text_3']}
                />
            </Box >
            <Box >
                <QuestionCardList />
            </Box>
        </Box>
    )
}


