


import {
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Container,
    Heading,
    Text
} from "@chakra-ui/react";

import { Box } from "@chakra-ui/react";
import CTAButton from "../CTAButton";



export default function FeatureCard({ supHeader, mainHeader, subHeader, variant = "solid" }: { supHeader: string, mainHeader: string, subHeader: string, variant?: string }) {


    return (



        <Container textAlign="left">
            <Card bg={variant == "negative" ? "rgba(0,0,0,0)" : 'white'} variant="filled">
                <CardHeader >
                    <Text fontSize="md" color={variant == "negative" ? 'white' : 'in3.500'} fontWeight='bold' >{supHeader}</Text>
                    <Heading fontSize="3xl" color={variant == "negative" ? 'white' : '#1a202c'} >{mainHeader}</Heading>
                </CardHeader>
                <CardBody>
                    <Text fontSize="lg" color={variant == "negative" ? 'white' : 'in3.800'} >{subHeader}</Text>
                </CardBody>
                <CardFooter >
                    <Box ml='auto'>
                        <CTAButton variant={variant == "negative" ? "outline" : "solid"} />
                    </Box>
                </CardFooter>
            </Card>
        </Container>

    );
};








