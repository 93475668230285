import { createSlice } from '@reduxjs/toolkit';

const initialState: any = []

const langSlice = createSlice({
    name: 'posts',
    initialState,
    reducers: {
        changeLanguage(state, actions) {
            if (actions.payload) {
                return actions.payload
            }
            else if (state === 'en-US') {
                return 'pt-BR';
            }
            else if (state === 'pt-BR') {
                return 'es-AR';
            }
            else if (state === 'es-AR') {
                return 'es-CL';
            }
            else if (state === 'es-CL') {
                return 'es-CO';
            }
            else if (state === 'es-CO') {
                return 'es-MX';
            }
            else if (state === 'es-MX') {
                return 'es-PE';
            }
            else if (state === 'es-PE') {
                return 'en-US';
            }
            else {
                return "en-US"
            }
        }
    }
})
export const { changeLanguage } = langSlice.actions

export default langSlice.reducer