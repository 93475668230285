

// import { 
//     BsArrowsMove } from "react-icons/bs";
//     import { SiGooglemarketingplatform } from "react-icons/si";


import { BsArrowDownUp, BsArrowsMove, BsChevronDoubleRight } from "react-icons/bs";
import { HiChartPie, HiOutlineArrowsExpand, HiOutlineBeaker } from "react-icons/hi";
import { IoIosTrendingUp } from "react-icons/io";
import { MdOutlineLocationCity } from "react-icons/md";
import { PiTarget } from "react-icons/pi";




export const IconDict: any = () => ({
    "GOTOMARKET": BsChevronDoubleRight,
    "MARKET EXPASION": BsArrowsMove,
    "GO/NO GO": BsArrowDownUp,
    // "usecases": UpDownIcon,
    // "questions": PlusSquareIcon,
    "Trends": IoIosTrendingUp,
    "Share": HiChartPie,
    "Size": HiOutlineArrowsExpand,
    "Procedures": HiOutlineBeaker,
    "Targets": PiTarget,
    "Segments": MdOutlineLocationCity,
    // "Trends": IoIosTrendingUp,
    // "Share": HiChartPie,
    // "Size": HiOutlineArrowsExpand,
    // "Procedures": HiOutlineCog,
    // "Targets": PiTarget,
    // "Segments": MdSegment,
})

