import {
    Box
} from "@chakra-ui/react"
import { useSelector } from 'react-redux'
import i18n_selector from "../../I18N"
import RoadmapCardList from "../../components/RoadmapCardList"
import RoadmapStep from "../../components/RoadmapStep"
import SectionHeader from "../../components/SectionHeader"



export const RoadMap = () => {
    const cardList:any = i18n_selector(useSelector((state: any) => state.langs))['RoadMap']   

    return(
    <Box >
        <Box >
            <SectionHeader
                supHeader={cardList['Text_1']}
                mainHeader={cardList['Text_2']}
                subHeader={cardList['Text_3']}
            />
        </Box >
        <Box px='5%' mx='auto' display={{ base: 'none', md: 'initial' }}>
            <RoadmapStep />
        </Box>
        <Box mt={"20px"} mx='auto'>
            <RoadmapCardList />

        </Box>

    </Box>
)}