


import {
    Badge,
    Card,
    CardBody,
    CardHeader,
    Center,
    Heading,
    Icon,
    Text,
    VStack
} from "@chakra-ui/react";

import { Box } from "@chakra-ui/react";
import { IconDict } from "../../IconDict";


export default function ConceptCard({ content, title, subtitle, color, icon }: { content: string, title: string, subtitle: string, color: string, icon: string }) {
    
return (
        <Card minH="400px" borderRadius="16px" textAlign={"center"} h='100%'>
            <CardHeader >
                <VStack align="center" px="10%" >
                    <Box>
                        <Badge bg={color+".200"} boxSize="80px" borderRadius="full">
                            <Center h='100%'>
                                <Icon as={IconDict()[icon]} boxSize="60%" color={color+".600"} />
                            </Center>
                        </Badge>
                    </Box>
                    <Box>
                        <Heading fontSize='22px'>{title}</Heading>
                    </Box>
                    <Box>
                        <Heading fontSize='18px'>{subtitle}</Heading>
                    </Box>
                </VStack>
            </CardHeader>
            <CardBody >
                <Text fontWeight='300' fontSize='16px' align="center">{content}</Text>
            </CardBody>
        </Card >

    );
};








