export const content_pt = () => ({
    "ConceptCardList": [
        {
            "title": "GO/NO GO",
            "subtitle": "Tomada de decisão baseada em dados estratégicos",
            "content": "Avalie cenários e oportunidades de mercado nos países latinos, faça análises comparativas e tome decisões sobre investimentos baseadas em dados estratégicos sobre potencial de mercado e ações da concorrência.",
            "icon": "GO/NO GO",
            "color": "purple"
        },
        {
            "title": "GO-TO-MARKET",
            "subtitle": "Planejamento assertivo e fundamentado para inovar",
            "content": "Analise tendências, projeções e os movimentos do mercado para planejar o lançamento de produtos e a introdução de estratégias comerciais nos países da América Latina, de acordo com as características específicas de cada um deles.",
            "icon": "GOTOMARKET",
            "color": "yellow"
        },
        {
            "title": "MARKET EXPASION",
            "subtitle": "Análises estratégicas para crescimento e consolidação de mercado",
            "content": "Explore oportunidades de crescimento nos territórios onde sua empresa já atua, identificando demandas, novos nichos e lacunas competitivas não exploradas pela concorrência.",
            "icon": "MARKET EXPASION",
            "color": "red"
        }
    ],
    "HomepageTextblock": {
        "header": "Sejam bem vindos ao primeiro Projeto Integrado de Inteligência para o Mercado de IVD da América Latina",
        "body": ["O objetivo deste projeto é gerar dados e informações precisas e aprofundadas para embasar o planejamento estratégico comercial e de marketing de empresas que atuam no mercado de IVD dos países latinos, na medida em que eleva-se o nível de competitividade deste mercado como um todo.", "Para isso, serão trabalhadas metodologias e ferrramentas de Big Data, Ciência de Dados e Inteligência Artificial para empoderar, com insights estratégicos, os profissionais de mercado das empresas, com vistas para assertividade do planejamento e da tomada de decisão."]
    },
    "QuestionCardList": [
        {
            "title": "Trends",
            "subtitle": "Mercado geral",
            "question": "Qual o potencial e as tendências de mercado de IVD da América Latina?",
            "frequency": "Relatório anual",
            "icon": "Trends"
        },
        {
            "title": "Share",
            "subtitle": "Produtos",
            "question": "Quais os níveis de participação de mercado dos players de IVD?",
            "frequency": "Relatório mensal",
            "icon": "Share"
        },
        {
            "title": "Size",
            "subtitle": "Mercado geral",
            "question": "Qual o Market Size de IVD dos países Latino-americanos?",
            "frequency": "Relatório mensal",
            "icon": "Size"
        },
        {
            "title": "Procedures",
            "subtitle": "Procedimentos",
            "question": "Qual a proporção de procedimentos realizados na América Latina?",
            "frequency": "Relatório mensal",
            "icon": "Procedures"
        },
        {
            "title": "Targets",
            "subtitle": "Estabelecimentos",
            "question": "Qual o perfil dos estabelecimentos de saúde de IVD na América Latina?",
            "frequency": "Relatório mensal",
            "icon": "Targets"
        },
        {
            "title": "Segments",
            "subtitle": "Canais",
            "question": "Como é segmentada a venda e distribuição dos produtos de IVD na América Latina?",
            "frequency": "Relatório mensal",
            "icon": "Segments"
        }
    ],
    "RoadmapCardList": [
        {
            "cycleName": "Primeiro Ciclo", "RoadMapDetails": [
                { "title": "Insights", "content": ["Dimensão e evolução dos mercados gerais"] },
                { "title": "Fonte dos dados", "content": ["importações por país", "Dados compartilhados"] },
                { "title": "Valor adesão", "content": ["Valor adesão: US$500.00 por mês, por país."] },
                { "title": "Benefícios", "content": ["Dados realistas e fidedignos do mercado de IVD"] },
            ]
        },
        {
            "cycleName": "Segundo Ciclo", "RoadMapDetails": [
                { "title": "Insights", "content": ["Dimensão e evolução dos mercados específicos"] },
                { "title": "Fonte dos dados", "content": ["importações por país", "Dados compartilhados", "Procedimentos por instituição"] },
                { "title": "Valor adesão", "content": ["A definir a partir do número de participantes"] },
                { "title": "Benefícios", "content": ["Análises específicas de linhas e sublinhas de produtos"] },
            ]
        },
        {
            "cycleName": "Terceiro Ciclo", "RoadMapDetails": [
                { "title": "Insights", "content": ["Perfil do público alvo"] },
                { "title": "Fonte dos dados", "content": ["importações por país", "Dados compartilhados", "Procedimentos por instituição", "Estabelecimentos por cidade"] },
                { "title": "Valor adesão", "content": ["A definir a partir do número de participantes"] },
                { "title": "Benefícios", "content": ["Análises para Inteligência Comercial"] },
            ]
        },
        {
            "cycleName": "Quarto Ciclo", "RoadMapDetails": [
                { "title": "Insights", "content": ["Critérios de decisão de compra e avaliação do parque instalado"] },
                { "title": "Fonte dos dados", "content": ["importações por país", "Dados compartilhados", "Procedimentos por instituição", "Estabelecimentos por cidade", "Dados primários (pesquisa de mercado)"] },
                { "title": "Valor adesão", "content": ["A definir a partir do número de participantes"] },
                { "title": "Benefícios", "content": ["Análises detalhadas sobre o público alvo e tendências de consumo"] },
            ]
        },
    ],
    "RoadmapStep": [
        // { description: "Kick-off", title: "Setembro/23" },
        { "description": "Início Projeto", "title": "Outubro / 2023" },
        { "description": "", "title": "Janeiro / 2024" },
        { "description": "", "title": "Abril / 2024" },
        { "description": "", "title": "Julho / 2024" },
    ],
    "CTAButton": {
        "buttonText": "Acesse a demonstração",
        "ModalHeader": "Informações para acesso",
        "modalbody": "Para acessar a demonstração do dashboard de Inteligência LATAM, use as informações a seguir:",
        "username": "Usuário:",
        "password": "Senha:",
        "cta": "Acesse",
    },
    "GlobeHero": {
        "gdp": "PIB:",
        "pop": "População:"

    },
    "SunburstHero": {
        "absoluteText": "Market Size total analisado",
        "percentageText": "Market Share do segmento selecionado",
        "Argentina": "Argentina",
        "Brasil": "Brasil",
        "Chile": "Chile",
        "Colômbia": "Colômbia",
        "México": "México",
        "Peru": "Peru",
        "Empresa_01": "Empresa 01",
        "Empresa_02": "Empresa 02",
        "Empresa_05": "Empresa 05",
        "Empresa_06": "Empresa 06",
        "Empresa_07": "Empresa 07",
        "Empresa_08": "Empresa 08",
        "Empresa_09": "Empresa 09",
        "Empresa_10": "Empresa 10",
        "Empresa_14": "Empresa 14",
        "Empresa_23": "Empresa 23",
        "Empresa_26": "Empresa 26",
        "Empresa_27": "Empresa 27",
        "Empresa_28": "Empresa 28",
        "Empresa_29": "Empresa 29",
        "Empresa_30": "Empresa 30",
        "Empresa_33": "Empresa 33",
        "Empresa_34": "Empresa 34",
        "Empresa_47": "Empresa 47",
        "Empresa_51": "Empresa 51",
        "Empresa_53": "Empresa 53",
        "Empresa_54": "Empresa 54",
        "Empresa_56": "Empresa 56",
        "Empresa_57": "Empresa 57",
        "Amostras": "Amostras",
        "Equipamentos": "Equipamentos",
        "Reagentes": "Reagentes",
        "Serviços": "Serviços"
    },
    "UseCases":
    {
        "Text_1": "Casos de uso da plataforma",
        "Text_2": "Tome decisões estratégicas baseadas em dados",
    },
    "RoadMap":
    {
        "Text_1": "O Roadmap",
        "Text_2": "Como vamos construir o projeto",
        "Text_3": "O projeto será desenvolvido de forma modular. A cada ciclo serão incorporadas novas bases de dados que ampliarão a abrangência das análises e trarão continuamente mais e novos insights. Nesse primeiro ciclo, começaremos com os seguintes países: Argentina, Brasil, Chile, Colombia, México e Peru, expandindo progressivamente com o andamento do projeto.",
    },
    "MarketSize":
    {
        "Text_1": "Séries Temporais",
        "Text_2": "Evolução do Market-Size de Diagnóstico In Vitro na América Latina",
        "Text_3": "Entenda o contexto do mercado e tendências",
        "Text_4": "marketSize.png"
    },
    "MarketShare":
    {
        "Text_1": "Na prática",
        "Text_2": "Como funcionam as análises",
        "Text_3": "Algumas demonstrações de como os insights são apresentados",
        "Text_4": "Análise dimensional",
        "Text_5": "Market-Share: Diagnóstico In Vitro na América Latina",
        "Text_6": "Analise o mercado de IVD da América Latina como um todo",
    },
    "KeyQuestions":
    {
        "Text_1": "Entenda mais",
        "Text_2": "Perguntas a serem respondidas",
        "Text_3": "Veja alguns insights que as análises podem trazer para seu negócio",
    },
    "Companies":
    {
        "Text_1": "EMPRESAS INTELIGENTES"
    }
})
