import {
    Center,
    Grid,
    GridItem
} from "@chakra-ui/react"
import CompaniesCard from "./CompaniesCard"

export default function CompaniesCardList() {








    const companiesList = [
        { "company": "Abbott", "companyLogo": "https://github.com/afflz/teste/blob/main/sqr_Abbott.png?raw=true" },
        { "company": "Agilent Dako", "companyLogo": "https://github.com/afflz/teste/blob/main/sqr_AgilentDako.png?raw=true" },
        { "company": "Alere", "companyLogo": "https://github.com/afflz/teste/blob/main/sqr_Alere.png?raw=true" },
        { "company": "Beckman Coulter", "companyLogo": "https://github.com/afflz/teste/blob/main/sqr_BeckmanCoulter.png?raw=true" },
        { "company": "Becton Dickinson", "companyLogo": "https://github.com/afflz/teste/blob/main/sqr_BectonDickinson.png?raw=true" },
        { "company": "Bio-Rad", "companyLogo": "https://github.com/afflz/teste/blob/main/sqr_bio-rad.png?raw=true" },
        { "company": "Bioclin Quibasa", "companyLogo": "https://github.com/afflz/teste/blob/main/sqr_BioclinQuibasa.png?raw=true" },
        { "company": "bioMérieux", "companyLogo": "https://github.com/afflz/teste/blob/main/sqr_bioMérieux.png?raw=true" },
        { "company": "Biosys Kovalent", "companyLogo": "https://github.com/afflz/teste/blob/main/sqr_BiosysKovalent.png?raw=true" },
        { "company": "Celer", "companyLogo": "https://github.com/afflz/teste/blob/main/sqr_Celer.png?raw=true" },
        { "company": "Cepheid", "companyLogo": "https://github.com/afflz/teste/blob/main/sqr_Cepheid.png?raw=true" },
        { "company": "Diasorin", "companyLogo": "https://github.com/afflz/teste/blob/main/sqr_Diasorin.png?raw=true" },
        { "company": "ECO Diagnóstica", "companyLogo": "https://github.com/afflz/teste/blob/main/sqr_ECODiagnóstica.png?raw=true" },
        { "company": "Elitech", "companyLogo": "https://github.com/afflz/teste/blob/main/sqr_Elitech.png?raw=true" },
        { "company": "Euroimmun", "companyLogo": "https://github.com/afflz/teste/blob/main/sqr_euroimmun.png?raw=true" },
        { "company": "Fresenius", "companyLogo": "https://github.com/afflz/teste/blob/main/sqr_Fresenius.png?raw=true" },
        { "company": "Grifols", "companyLogo": "https://github.com/afflz/teste/blob/main/sqr_Grifols.png?raw=true" },
        { "company": "Horiba", "companyLogo": "https://github.com/afflz/teste/blob/main/sqr_Horiba.png?raw=true" },
        { "company": "IBMP", "companyLogo": "https://github.com/afflz/teste/blob/main/sqr_IBMP.png?raw=true" },
        { "company": "IDS", "companyLogo": "https://github.com/afflz/teste/blob/main/sqr_IDS.png?raw=true" },
        { "company": "Labinbraz", "companyLogo": "https://github.com/afflz/teste/blob/main/sqr_Labinbraz.png?raw=true" },
        { "company": "Labtest", "companyLogo": "https://github.com/afflz/teste/blob/main/sqr_Labtest.png?raw=true" },
        { "company": "Leica", "companyLogo": "https://github.com/afflz/teste/blob/main/sqr_Leica_Biosystems.png?raw=true" },
        { "company": "Medivax", "companyLogo": "https://github.com/afflz/teste/blob/main/sqr_Medvax.png?raw=true" },
        { "company": "Mindray", "companyLogo": "https://github.com/afflz/teste/blob/main/sqr_Mindray-Logo.png?raw=true" },
        { "company": "PerkinElmer", "companyLogo": "https://github.com/afflz/teste/blob/main/sqr_PerkinElmer.png?raw=true" },
        { "company": "Plast Labor", "companyLogo": "https://github.com/afflz/teste/blob/main/sqr_PlastLabor.png?raw=true" },
        { "company": "Prodimol", "companyLogo": "https://github.com/afflz/teste/blob/main/sqr_prodimol.png?raw=true" },
        { "company": "Qiagen", "companyLogo": "https://github.com/afflz/teste/blob/main/sqr_Qiagen.png?raw=true" },
        { "company": "QuidelOrtho", "companyLogo": "https://github.com/afflz/teste/blob/main/sqr_quidelortho.png?raw=true" },
        { "company": "REM", "companyLogo": "https://github.com/afflz/teste/blob/main/sqr_REM.png?raw=true" },
        { "company": "Roche", "companyLogo": "https://github.com/afflz/teste/blob/main/sqr_Roche.png?raw=true" },
        { "company": "Sebia", "companyLogo": "https://github.com/afflz/teste/blob/main/sqr_Sebia.png?raw=true" },
        { "company": "Seegene", "companyLogo": "https://github.com/afflz/teste/blob/main/sqr_seegene_logo.png?raw=true" },
        { "company": "Siemens", "companyLogo": "https://github.com/afflz/teste/blob/main/sqr_Siemens.png?raw=true" },
        { "company": "Stago", "companyLogo": "https://github.com/afflz/teste/blob/main/sqr_Stago.png?raw=true" },
        { "company": "Sysmex", "companyLogo": "https://github.com/afflz/teste/blob/main/sqr_Sysmex.png?raw=true" },
        { "company": "TBS Binding Site", "companyLogo": "https://github.com/afflz/teste/blob/main/sqr_TBSBindingSite.png?raw=true" },
        { "company": "Thermo Fisher", "companyLogo": "https://github.com/afflz/teste/blob/main/sqr_ThermoFisher2.png?raw=true" },
        { "company": "Trinity Biotech", "companyLogo": "https://github.com/afflz/teste/blob/main/sqr_TrinityBiotech.png?raw=true" },
        { "company": "Vyttra Diagnósticos", "companyLogo": "https://github.com/afflz/teste/blob/main/sqr_VyttraDiagnósticos.png?raw=true" },
        { "company": "Wama", "companyLogo": "https://github.com/afflz/teste/blob/main/sqr_Wama.png?raw=true" },
        { "company": "Werfen", "companyLogo": "https://github.com/afflz/teste/blob/main/sqr_Werfen.png?raw=true" },
    ]


    return (
        <Grid templateColumns={{ base: "repeat(3, 1fr)", md: "repeat(10, 1fr)"}} gap={5} pt='25px'>
            {companiesList.map((companyInfo, index) => (
                <Center key={index}>
                    <GridItem colSpan={1}>
                        <CompaniesCard companyInfo={companyInfo} />
                    </GridItem>
                </Center>
            ))}

        </Grid>

    )
}






