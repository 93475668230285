import {
    Center,
    Grid,
    GridItem
} from "@chakra-ui/react";
import { useSelector } from 'react-redux';
import QuestionCard from "./QuestionCard";

import i18n_selector from "../../I18N";

export default function QuestionCardList() {

   const cardList:any = i18n_selector(useSelector((state: any) => state.langs))['QuestionCardList']   

    return (

        <Grid templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(3, 1fr)"}} gap={5} pt='25px'>
            {cardList.map((cardInfo:any, index:any) => (
                <Center key={index}>
                    <GridItem  maxW='80%' h='100%' colSpan={1}>
                        <QuestionCard {...cardInfo} />
                    </GridItem>
                </Center>
            ))}

        </Grid>

    )
}


