import {
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Flex,
    Heading,
    Icon,
    Tag,
    Text
} from "@chakra-ui/react";

import { Box } from "@chakra-ui/react";
import { IconDict } from "../../IconDict";



export default function QuestionCard({ question, title, subtitle, frequency, icon }: { question: string, title: string, subtitle: string, frequency: string, icon: string }) {

    return (


        <Card h='100%' >
            <CardHeader>
                <Flex >
                    <Flex flex='1' gap='6' alignItems='center' flexWrap='wrap'>
                        <Box h={"100%"}>
                            <Icon as={IconDict()[icon]} h='100%' w="auto" />
                        </Box>
                        <Box>
                            <Heading fontSize='md'>{title}</Heading>
                            <Text fontSize='sm' color='#B5B8C6'>{subtitle}</Text>
                        </Box>
                    </Flex>
                </Flex>
            </CardHeader>
            <CardBody>
                <Heading fontSize='lg'>
                    {question}
                </Heading>
            </CardBody>

            <CardFooter

            >
                <Tag bg='#F8ECE8'
                    color='#F36532'
                    size="lg"
                >
                    <Text fontSize='sm'>
                        {frequency}
                    </Text>

                </Tag>

            </CardFooter>
        </Card >


    );
};








