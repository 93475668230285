import {
    Box,
    Center,
    Grid,
    GridItem
} from "@chakra-ui/react"
import GlobeContainer from "../../components/GlobeContainer"
import HomepageTextblock from "../../components/HomepageTextblock"
import Navbar from "../../components/Navbar"

export const Frontpage = () => (
    <Box>
        <Navbar />
        <Grid 

        templateColumns='repeat(12, 1fr)' gap={0} pt='1rem'>
            <GridItem ml={{base:0,lg:'2rem'}} h='auto' colSpan={{ base: 12, lg: 7}}>
                <Center h='100%' ><HomepageTextblock /></Center>
            </GridItem>
            <GridItem  w='100%' h='auto' colSpan={{ base: 12, lg: 5}}>
                <GlobeContainer />
            </GridItem>
        </Grid>
    </Box>
)