import { useEffect, useRef } from "react";
import Globe from 'react-globe.gl';
import { useSelector } from 'react-redux';
import CountriesShapes from '../../../data/CountriesShapes';

import i18n_selector from "../../../I18N";

export default function GlobeHero({ w, h }: { w: number, h: number }) {

    const world = useRef<any | null>(null);

    var worldDict: any = {};



    function currencyFormatter(num: any) {
        const lookup = [
            { value_ceil: 1, value: 1, symbol: "", decimal_points: 2 },
            { value_ceil: 1, value: 1e1, symbol: "", decimal_points: 2 },
            { value_ceil: 1, value: 1e2, symbol: "", decimal_points: 2 },
            { value_ceil: 1e3, value: 1e3, symbol: " K", decimal_points: 2 },
            { value_ceil: 1e3, value: 1e4, symbol: " K", decimal_points: 2 },
            { value_ceil: 1e3, value: 1e5, symbol: " K", decimal_points: 2 },
            { value_ceil: 1e6, value: 1e6, symbol: " Mi", decimal_points: 2 },
            { value_ceil: 1e6, value: 1e7, symbol: " Mi", decimal_points:  2},
            { value_ceil: 1e6, value: 1e8, symbol: " Mi", decimal_points: 2 },
            { value_ceil: 1e9, value: 1e9, symbol: " Bi", decimal_points: 2 },
            { value_ceil: 1e9, value: 1e10, symbol: " Bi", decimal_points: 2 },
            { value_ceil: 1e9, value: 1e11, symbol: " Bi", decimal_points: 2 },
            { value_ceil: 1e12, value: 1e12, symbol: " Tri", decimal_points: 2 },
        ];
        const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
        var item = lookup
            .slice()
            .reverse()
            .find(function (item) {
                return Math.abs(num) >= item.value;
            });
        var returnable = item
            ? (Math.abs(num) / item.value_ceil).toFixed(item.decimal_points) +
            item.symbol
            : "0";
        var negative_sign = num < 0 ? "-" : "";
        var currency = "$";

        return negative_sign + currency + returnable;
    }

    var latam_countries = ["Argentina",
        "Bolivia",
        "Brazil",
        "Chile",
        "Colombia",
        "Costa Rica",
        "Cuba",
        "Dominican Republic",
        "Ecuador",
        "Guatemala",
        "Guyana",
        "Honduras",
        "Haiti",
        "Jamaica",
        "Mexico",
        "Nicaragua",
        "Panama",
        "Peru",
        "Paraguay",
        "El Salvador",
        "Suriname",
        "Uruguay",
        "Venezuela"]

    var project_countries =
        ["Argentina",
            "Brazil",
            "Chile",
            "Colombia",
            "Mexico",
            "Peru",]


    function get_color(d: any) {


        if (project_countries.includes(d)) {
            return "rgba(18, 72, 109, 0.95)";
        }
        else if (latam_countries.includes(d)) {
            return "rgba(36, 140, 170, 0.85)";
        }
        else return "rgba(156, 150, 180, 0.65)"

    }

    const MINUTE_MS = 1000;
    var speed = 0.2;


    useEffect(() => {
        if (world.current) {
            world.current.controls().autoRotate = true;
            world.current.pointOfView({ lat: -5, lng: -35, altitude: 1.45 })
            const interval = setInterval(() => {
                speed = speed * 1.01;
                if (speed < 0.7) {
                    world.current.controls().autoRotateSpeed = speed;
                }
            }, MINUTE_MS);

            return () => clearInterval(interval);
        }
    }, [world]);

    const markerSvg = `<svg viewBox="-4 0 36 36">
    <path fill="currentColor" d="M14,0 C21.732,0 28,5.641 28,12.6 C28,23.963 14,36 14,36 C14,36 0,24.064 0,12.6 C0,5.641 6.268,0 14,0 Z"></path>
    <circle fill="black" cx="14" cy="14" r="7"></circle>
  </svg>`;

    // const gData = (Locations() as any)
    // .filter((places: any) => latam_countries.includes(places.properties.sov0name) && places.properties.featurecla=="Admin-0 capital")
    // .map((places: any) => {
    //     console.log(places)
    //     return ({
    //     lat: places.properties.latitude,
    //     lng: places.properties.longitude,
    //     markerName: places.properties.sov0name,
    //     size: 20,
    //     color: "rgba(30,30,30,1)"
    // })})

    // worldDict['htmlElement'] = ((d: any) => {
    //     const el: any = document.createElement('div');
    //     el.innerHTML = markerSvg;
    //     el.style.color = d.color;
    //     el.style.width = `10px`;
    //     el.style['pointer-events'] = 'auto';
    //     el.style.cursor = 'pointer';
    //     el.onclick = () => console.info(d);
    //     return el;
    // })

    // worldDict['htmlElementsData'] = (gData)



    worldDict['showGlobe'] = (true)
    worldDict['backgroundColor'] = "rgba(0,0,0,0)"
    worldDict['globeImageUrl'] = "Presentation1.png"

    const pageText = i18n_selector(useSelector((state: any) => state.langs))['GlobeHero']


    CountriesShapes().map(countries => {
        worldDict['polygonsData'] = (countries.features)
        worldDict['polygonAltitude'] = (0.005)
        // worldDict['polygonCapMaterial'] = (new MeshLambertMaterial({ color: 'rgba(56, 150, 180, 0.85)', side: DoubleSide }))
        worldDict['polygonCapColor'] = (({ properties: d }: any) => get_color(d.ADMIN))
        worldDict['polygonSideColor'] = (() => 'rgba(0, 0, 0, 0)')
        worldDict['polygonStrokeColor'] = (() => 'rgba(255, 255, 255, 0.5)')
        worldDict['polygonLabel'] = (({ properties: d }: any) => `
        
        <div style="background-color:rgba(180, 180, 220, 0.85); padding:10px; border-radius: 20px;" p='1'>
        <b>${d.ADMIN}</b> <br />${pageText['gdp']} <i>${currencyFormatter(d.GDP_MD_EST * 1000000)}</i><br/>${pageText['pop']} <i>${String(d.POP_EST).replace(/(.)(?=(\d{3})+$)/g, '$1,')}</i>
      </Box>
    
        `)

        // {
        //     // "type": "Feature", "properties": { "scalerank": 1, "featurecla": "Admin-0 country", "LABELRANK": 3, "SOVEREIGNT": "Afghanistan", "SOV_A3": "AFG", "ADM0_DIF": 0, "LEVEL": 2, "TYPE": "Sovereign country", "ADMIN": "Afghanistan", "ADM0_A3": "AFG", "GEOU_DIF": 0, "GEOUNIT": "Afghanistan", "GU_A3": "AFG", "SU_DIF": 0, "SUBUNIT": "Afghanistan", "SU_A3": "AFG", "BRK_DIFF": 0, "NAME": "Afghanistan", "NAME_LONG": "Afghanistan", "BRK_A3": "AFG", "BRK_NAME": "Afghanistan", "BRK_GROUP": null, "ABBREV": "Afg.", "POSTAL": "AF", "FORMAL_EN": "Islamic State of Afghanistan", "FORMAL_FR": null, "NAME_CIAWF": "Afghanistan", "NOTE_ADM0": null, "NOTE_BRK": null, "NAME_SORT": "Afghanistan", "NAME_ALT": null, "MAPCOLOR7": 5, "MAPCOLOR8": 6, "MAPCOLOR9": 8, "MAPCOLOR13": 7, "POP_EST": 34124811, "POP_RANK": 15, "GDP_MD_EST": 64080, "POP_YEAR": 2017, "LASTCENSUS": 1979, "GDP_YEAR": 2016, "ECONOMY": "7. Least developed region", "INCOME_GRP": "5. Low income", "WIKIPEDIA": -99, "FIPS_10_": "AF", "ISO_A2": "AF", "ISO_A3": "AFG", "ISO_A3_EH": "AFG", "ISO_N3": "004", "UN_A3": "004", "WB_A2": "AF", "WB_A3": "AFG", "WOE_ID": 23424739, "WOE_ID_EH": 23424739, "WOE_NOTE": "Exact WOE match as country", "ADM0_A3_IS": "AFG", "ADM0_A3_US": "AFG", "ADM0_A3_UN": -99, "ADM0_A3_WB": -99, "CONTINENT": "Asia", "REGION_UN": "Asia", "SUBREGION": "Southern Asia", "REGION_WB": "South Asia", "NAME_LEN": 11, "LONG_LEN": 11, "ABBREV_LEN": 4, "TINY": -99, "HOMEPART": 1, "MIN_ZOOM": 0, "MIN_LABEL": 3, "MAX_LABEL": 7 }

        //     // .polygonLabel(({ properties: d }) => `<b>${d.ADMIN} (${d.ISO_A2}):</b> <br />GDP: <i>${d.GDP_MD_EST}</i> M$<br/>Population: <i>${d.POP_EST}</i>`)
        //     // .onPolygonHover(hoverD => world
        //     //     .polygonAltitude(d => d === hoverD ? 0.02 : 0.01)
        //     //     .polygonCapColor(d => d === hoverD ? 'rgba(56, 150, 200, 0.85)' : 'rgba(56, 150, 180, 0.85)')
        //     // )
        //     // .polygonsTransitionDuration(100)
    });



    // { ({ size }) => <div>My width is {size.width}px</div> }</SizeMe >
    // var size = {
    //     'width' : 800,
    // }
    // var width = 800
    // width={size.width} height={size.width? size.width : width *(3 / 4)}
    return (

        // <SizeMe>{({ size }) => (
        <Globe ref={world}
            showGlobe={false}
            showAtmosphere={false}
            width={w}
            height={w}
            {...worldDict} />
        // )}</SizeMe>
    );
};







// // Gen random data
// const N = 10;
// // const arcsData = [...Array(N).keys()].map(() => ({
// //     startLat: ((Math.random()) - 0.7) * 80,//(Math.random() - 0.5) * 0,
// //     startLng: ((Math.random()) - 0.5) * 270 + 30,//(Math.random() - 0.5) * 0,
// // //     endLat: ((Math.random()) - 0.3) * 80,//(Math.random()) * 360,
// // //     endLng: ((Math.random()) - 0.5) * 270 + 30,//(Math.random()) * 360,
// // //     color: ['#D8F8FF', "#317877"]
// // // }));

// const world = Globe()
//     // .globeImageUrl('world.jpg')
//     .
//         .
//     // .backgroundColor("#DCDCEA")
//     // .arcsData(arcsData)
//     // .arcColor('color')
//     // .arcDashLength(() => 0.3)
//     // .arcDashGap(() => 0.9)
//     // .arcDashAnimateTime(() => 1000 )



//


// world.controls().autoRotate = true;
// world.controls().autoRotateSpeed = 1;


// // }


