import { Box, ChakraProvider, } from "@chakra-ui/react";
import theme from "./themes/ExtendedTheme";

import { useDispatch } from 'react-redux';
import { Companies } from "./pages/Companies";
import { Frontpage } from "./pages/Frontpage";
import { KeyQuestions } from "./pages/KeyQuestions";
import { MarketShare } from "./pages/MarketShare";
import { MarketSize } from "./pages/MarketSize";
import { RoadMap } from "./pages/RoadMap";
import { UseCases } from "./pages/UseCases";
import { changeLanguage } from './services/lang';



import i18n from 'i18next';

import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18n
  .use(LanguageDetector)
  .use(initReactI18next).init();
i18n.changeLanguage()




export default function App() {


  const dispatch = useDispatch()

  dispatch(changeLanguage(i18n.language))
  console.log(i18n.language)

  return (
    <ChakraProvider theme={theme}>

      <Box>
        <Box minH='100vh'><Frontpage /></Box>
        <Box pt="2%"><UseCases /></Box>
        <Box px={"5%"}><KeyQuestions /></Box>
        {/* <Box ' pt={"5%"}></Box> */}
        <Box px={"5%"}><MarketShare /></Box>
        <Box><MarketSize /></Box>
        <Box px={"5%"}><RoadMap /></Box>
        <Box px={"5%"}><Companies /></Box>
      </Box >
    </ChakraProvider >
  )
}
