import {
    Box,
    Center,
    Grid,
    GridItem
} from "@chakra-ui/react"
import FeatureCard from "../../components/FeatureCard"
import SectionHeader from "../../components/SectionHeader"
import SunburstContainer from "../../components/SunburstContainer"

import i18n_selector from "../../I18N"

import { useSelector } from 'react-redux'


export const MarketShare = () => {
    const cardList:any = i18n_selector(useSelector((state: any) => state.langs))['MarketShare']   

    return(
    <Box>
        <Box>
            <SectionHeader
                supHeader={cardList["Text_1"]}
                mainHeader={cardList["Text_2"]}
                subHeader={cardList["Text_3"]}
            />
        </Box>
        <Grid templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)"}} gap={0}>
            <GridItem w='100%' h='auto' colSpan={1}>
                <SunburstContainer  />
            </GridItem>
            <GridItem w='100%' h='auto' colSpan={1}>
                <Center h='100%' >
                    <FeatureCard
                        supHeader={cardList["Text_4"]}
                        mainHeader={cardList["Text_5"]}
                        subHeader={cardList["Text_6"]}
                    />
                </Center>
            </GridItem>
        </Grid>
    </Box>
)}


