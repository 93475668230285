


import {
    Container,
    Heading,
    Stack,
    Text
} from "@chakra-ui/react";
import { useSelector } from 'react-redux';

import i18n_selector from "../../I18N";

export default function HomepageTextblock() {

    const texts:any = i18n_selector(useSelector((state: any) => state.langs))['HomepageTextblock']

    return (

        <Container maxW='100%' centerContent textAlign="center">

            <Heading fontFamily='montserrat' fontWeight='800' fontSize={['2xl', '4xl']}>{texts.header}</Heading>

            <Stack mt='30px' spacing={4}>
                {texts.body.map((text:any, index:any) => (
                    <Text key={index} fontFamily='inter' fontWeight='400' fontSize={['sm', 'md', 'xl']} > {text}</Text>
                ))}
            </Stack>

        </Container >

    );
};








